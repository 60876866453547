<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import _debounce from 'lodash.debounce';
import EmptyList from "@/components/widgets/empty_list"
import DropZone from "@/components/widgets/dropZone";
import { required } from "vuelidate/lib/validators";

import helpers from '../../../helpers'

import {
	audienceMethods,
  commonMethods
} from "@/state/helpers";

export default {
  page: {
    title: "Contacts List",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    EmptyList,
    DropZone
  },
  data() {
    return {
      title: "",
      items: [],
			currentPage:1,
			perPage:9,
      rows:0,
			isLoading:false,
      contactLists:[],
      confirmDelete:false,
      contactList: {
        url: null
      },
      query:'',
      empty_list_config:{
            "title" : this.$t("contact_lists.empty_search_title"),
            "linkButtonText" : this.$t("common.reset_search"),
            icon: "fa-cubes"
        },
        empty_config:{
            "title" : this.$t("contact_lists.empty_title"),
            "subtitle" : this.$t("contact_lists.empty_text"),
            "buttonText" : this.$t("contact_lists.new"),
            academy: "https://academy.gopersonal.ai/guia-de-usuario/segmentacion/lista-de-contactos",
            icon: "fa-cubes"
        },
        showCopyToProjectModal : false,
        showNewListModal:false,
        fileName: '',
        submitted:false,
        template_url : `${window.config.VUE_APP_BACKEND}/api/contact-lists/download-template`,
        fileObj: null,
        lastUrl: null
    };
  },
  validations: {
    contactList: {
      name: {
        required,
      },
      url: {
        required,
      }
    }
  },
  created(){
		this.debounceContactLists = _debounce(this.searchContactLists.bind(this), 1000);
	},
	mounted(){
		this.loadContactLists();
	},
  methods:{
		...audienceMethods,
    ...commonMethods,
    ...helpers,

    onDropFileZoneSelected(obj){

      if(obj?.file){
        let loader = this.$loading.show();

				this.uploadMedia(obj?.file).then((res) => {
          this.contactList.url = res.data.url;
          this.contactList.fileName = res.data.originalname;
          this.fileName = res.data.originalname;
        }).catch(() => {
					this.$notify({ type: 'error', text: 'There was an error uploading the file',title:  this.$t("contactLists.title") });
				}).finally(()=>{
          loader.hide()
        })
      }
    },

    onRemoveContactList(contactList){
      this.contactList = contactList;
      this.confirmDelete = true;
    },
    onConfirmRemoveContactListClicked(){
      let loader = this.$loading.show();

      this.deleteContactList(this.contactList._id).then(()=>{
        this.currentPage=1;
        this.loadContactLists();
        this.$notify({ type: 'success', text: this.$t("contact_lists.delete_contact_list_success"),title:  this.$t("contact_lists.title") });
      }).catch(()=>{
        this.$notify({ type: 'error', text: this.$t("contact_lists.delete_contact_list_error"),title:  this.$t("contact_lists.title") });
      }).finally(()=>{
        loader.hide();
      })
    },
		loadContactLists(){
			
      let loader = this.$loading.show();
      this.isLoading = true;
      
			const params = {
				q : `where[project]=${localStorage.getItem('current_project')}&limit=${this.perPage}&skip=${this.perPage * (this.currentPage-1)}`
			}
			this.getContactLists(params).then((res)=>{
        if(res&& res.data){
          this.contactLists= res.data;
          this.rows= res.totalCount
				}
      }).catch(()=>{
        this.$notify({ type: 'error', text: this.$t("contact_lists.get_contact_lists_error"),title:  this.$t("contact_lists.title") });
      }).finally(()=>{
        this.isLoading = false;
        loader.hide();
      });
    },
    searchContactLists(query){
      
      let loader = this.$loading.show();
      this.isLoading = true;
      
      this.query = query;
      this.empty_list_config.subtitle =  this.$t("contact_lists.empty_search_text").replaceAll('#', this.query);
      
      if(query){
        this.contactLists=[];
        this.currentPage = 1;
				const params={
					q: `where[project]=${localStorage.getItem("current_project")}&where[name][$regex]=.*${query}.*&where[name][$options]=i&limit=${this.perPage}&skip=${this.perPage * (this.currentPage-1)}`,
				}

        this.getContactLists(params).then((res)=>{
          if(res&& res.data){
            this.contactLists= res.data;
            this.rows= res.totalCount
          }
          this.isLoading = false;
          loader.hide();
        });
      }else{
        this.isLoading = false;
        this.currentPage = 1;
        loader.hide();
        this.loadContactLists();
      }
    },
		onContactListPageClicked(){
			this.loadContactLists();
		},	
    onEditContactListClicked(data){
      this.contactList = data;
      this.fileName = data.fileName || 'contact-list-file.csv';
      this.showNewListModal = true;
    },
    onCreateContactListClicked(){
      this.contactList = {
        url: null
      }
      this.submitted = false
      this.showNewListModal = true;
    },
    onResetSearchClicked(){
      this.query = '';
      this.loadContactLists();
    },
    onDownloadTemplateClicked(){
      window.open(this.template_url);
    },
    onRemoveFileClicked(){
      this.contactList.url = null;
      this.fileName = '';
    },
    onConfirmCreateContactListClicked(){
      this.submitted = true;
			this.$v.$touch();

			if (this.$v.$invalid) {
				return;
			}

      let loader = this.$loading.show();

      this.contactList.project = localStorage.getItem('current_project');

      if(!this.contactList._id){
        this.createContactList(this.contactList).then(()=>{
          this.$notify({ type: 'success', text: this.$t("contact_lists.create_contact_list_success"),title:  this.$t("contact_lists.title") });
          this.currentPage = 1;
          this.showNewListModal = false
          this.loadContactLists();
        
        }).catch(()=>{
          this.$notify({ type: 'error', text: this.$t("contact_lists.create_contact_list_error"),title:  this.$t("contact_lists.title") });
        }).finally(()=>{
          loader.hide();
        })
      }else{
        this.updateContactList(this.contactList).then(()=>{
          this.$notify({ type: 'success', text: this.$t("contact_lists.update_contact_list_success"),title:  this.$t("contact_lists.title") });
          this.currentPage = 1;
          this.showNewListModal = false
          this.loadContactLists();
        }).catch(()=>{
          this.$notify({ type: 'error', text: this.$t("contact_lists.update_contact_list_error"),title:  this.$t("contact_lists.title") });
        }).finally(()=>{
          loader.hide();
        })
      }
    },
    onErrorsClicked(data){
      window.open(data.errors)
    },
    
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="$t('contact_lists.title')" :items="items" />
    <div class="row">
      <div class="col">
        <div class="card">
				<div class="card-body">
          <div class="row ">
            <div class="col-sm-4">
                <div class="search-box me-2 mb-0 d-inline-block">
                  <div class="position-relative">
                      <input
                      type="text"
                      class="form-control"
                      :placeholder="$t('common.search')"
                      @input="debounceContactLists($event.target.value)"
                      v-model="query"
                      />
                      <i class="bx bx-search-alt search-icon"></i>
                  </div>
                </div>
            </div>
            <div class="col-sm-8">
                <div class="text-sm-end">
                <button
                    type="button"
                    class="btn btn-primary mb-0 me-0"
                    @click="onCreateContactListClicked"
                    v-if="contactLists.length>0 || query!=''">
                    <i class="mdi mdi-plus me-1"></i> {{$t('contact_lists.new')}}
                </button>
                </div>
            </div>
          </div>
          <EmptyList :config="empty_list_config" v-if="!isLoading && contactLists.length == 0 && query!=''" @onButtonClicked="onResetSearchClicked" class="mt-3 mb-3"/>
          <EmptyList :config="empty_config" v-if="!isLoading && contactLists.length == 0 && query ==''" @onButtonClicked="onCreateContactListClicked" class="mt-3 mb-3"/>
        </div>
          <div class="col-lg-12" v-if="!isLoading && contactLists.length > 0">
            <div>
              <div class="table-responsive">
                <table class="table align-middle table-nowrap table-hover mb-0">
                  <thead class="table-light">
                    <tr>
                      <th scope="col">{{$t('contact_lists.name')}}</th>
                      <th scope="col">{{$t('contact_lists.creation_date')}}</th>
                      <th scope="col">{{$t('contact_lists.last_update_date')}}</th>
                      <th scope="col">{{$t('contact_lists.status')}}</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="data in contactLists" :key="data._id">
                      <td>
                        <h5 class="text-truncate font-size-14 mb-0 interact" v-on:click="onEditContactListClicked(data)">
                          <a href="javascript: void(0);" class="text-dark">{{
                            data.name
                          }}</a>
                        </h5>
                        <p class="text-muted mb-0 small interact" v-if="data.customers && data.customers > 0"> {{`${data.customers} ${$t("contact_lists.customers")}` }} </p>
                      </td>
                      <td>
                        {{ `${parseDateWithLocale(data.createdAt, {year: 'numeric', month: 'long', day: 'numeric'})} ${parseTimeWithLocale(data.createdAt, {hour: '2-digit', minute:'2-digit'})}` }}
                      </td>
                      <td>
                        {{ `${parseDateWithLocale(data.updatedAt, {year: 'numeric', month: 'long', day: 'numeric'})} ${parseTimeWithLocale(data.updatedAt, {hour: '2-digit', minute:'2-digit'})}` }}
                      </td>
                      <td>
                        <span class="badge font-size-10"
                          :class="{
                          'bg-success': `${data.status}` == 'completed',
                          'bg-warning': `${data.status}` == 'pending'}">
                            {{ $t(`contact_lists.status_${data.status}`)}}
                        </span>
                      </td>
                      <td class="text-end">
                        <i class="fas fa-exclamation-triangle text-warning me-2 interact" v-on:click="onErrorsClicked(data)" v-if="data.errors != null"></i>
                        <i class="fas fa-edit text-success me-2 interact" v-on:click="onEditContactListClicked(data)"></i>
                        <i class="fas fa-trash-alt text-danger me-1 interact" v-on:click="onRemoveContactList(data)"></i>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="row mb-4" v-if="rows > perPage">
                <div class="col">
                    <div class="dataTables_paginate paging_simple_numbers float-end">
                        <ul class="pagination pagination-rounded mb-0">
                            <b-pagination
                                @input="onContactListPageClicked"
                                v-model="currentPage"
                                :total-rows="rows"
                                :per-page="perPage"
                            ></b-pagination>
                        </ul>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <b-modal v-model="confirmDelete" id="modal-center" centered :title="$t('common.confirm')" title-class="font-18" @ok="onConfirmRemoveContactListClicked"  :okTitle="$t('common.confirm')"  :cancelTitle="$t('common.cancel')">
            <p>{{$t('contact_lists.remove_contact_list')}}</p>
        </b-modal>
        <b-modal
          :title=" contactList._id? $t('contact_lists.edit') : $t('contact_lists.new')"
          v-model="showNewListModal"
          :okTitle="$t('common.confirm')"  :cancelTitle="$t('common.cancel')"
          @ok.prevent="onConfirmCreateContactListClicked"
          @cancel="showNewListModal = false">
          <div>
            <div class="row">
              <div class="col">
                <label class="mb-0">{{$t('contact_lists.name')}}</label>
                <input class="form-control mb-2" v-model="contactList.name" :class="{'is-invalid': submitted && $v.contactList.name.$error,}" :placeholder="$t('contact_lists.name')" />
                <div v-if="submitted && !$v.contactList.name.required" class="invalid-feedback">
                  {{$t('contact_lists.name_required')}}
                </div>
              </div>
            </div>
            
            <div class="row">
              <div class="col">
                <label class="mb-0">{{$t('contact_lists.file')}}</label>
                <DropZone v-if="contactList.url==null" ref="dropZone" :accept="'text/csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'" @onFile="onDropFileZoneSelected" :field="{}" :active="false" />
                <div v-else>
                  <div class="d-inline-flex align-items-baseline">
                    <a :href="contactList.url" class="text-decoration-underline">{{fileName}}</a> <i class="bx bx-trash ms-2 text-danger" v-on:click="onRemoveFileClicked"></i>
                  </div>
                </div>
                <div v-if="submitted && !contactList.url" class="invalid-feedback d-block">
                  {{$t('contact_lists.file_required')}}
                </div>
              </div>
            </div>
            <div class="row mb-3 text-decoration-underline interact" v-on:click="onDownloadTemplateClicked" v-if="!contactList.url">
            <a> <i class="bx bx-download"/> {{$t('contact_lists.template')}}  </a>
            </div>
          </div>
        </b-modal>
    </div>
    <!-- end row -->
  </Layout>
</template>
<style scoped>
.interact{
  cursor: pointer;
}
</style>
